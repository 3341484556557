import styles from "./footer.module.scss";
import client from "../../client";
import Image from "next/image";
import Link from "next/link";
import footerLogo from "../../public/images/loop-logo-black-tm.png";
import instagramLogo from "../../public/images/instagram-social.svg";
import facebookLogo from "../../public/images/facebook-social.svg";
import linkedinLogo from "../../public/images/linkedin-social.svg";
import twitchLogo from "../../public/images/twitch-social.svg";
import twitterLogo from "../../public/images/twitter-social.svg";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export default function Footer() {
  const router = useRouter();
  const [data, setData] = useState(null)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    fetch('/api/getFooterLinks')
      .then((res) => res.json())
      .then((data) => {
        setData(data)
        setLoading(false)
      })
  }, [])

  return (
    <footer className={styles.footer}>
      <div className={styles.inner}>
        <div className={styles.col}>
          <div className={styles.wrap}>

          <div className={`${styles.mobileOnly} ${styles.social}`}>
              <Link href="https://www.instagram.com/loopforbusiness/">
                <a target="_blank">
                  <Image src={instagramLogo} alt="Instagram" />
                </a>
              </Link>
              <Link href="https://www.facebook.com/loopforbusiness/">
                <a target="_blank">
                  <Image src={facebookLogo} alt="Facebook" />
                </a>
              </Link>
              <Link href="https://www.linkedin.com/company/looptv/">
                <a target="_blank">
                  <Image src={linkedinLogo} alt="LinkedIn" />
                </a>
              </Link>
              <Link href="https://www.twitch.tv/loopmediainc">
                <a target="_blank">
                  <Image src={twitchLogo} alt="Twitch" />
                </a>
              </Link>
              <Link href="https://twitter.com/loopforbusiness">
                <a target="_blank">
                  <Image src={twitterLogo} alt="Twitter" />
                </a>
              </Link>              
            </div>

            <div className={styles.label}>
              <div className={styles.logo}>
                <Image src={footerLogo} alt="Loop" />
              </div>
              <h3>Los Angeles</h3>
            </div>
            <p>
              700 n central avenue
              <br />
              suite 430, glendale
              <br />
              ca 91203
            </p>

            <p>phone: <a href="tel:2134362100">213.436.2100</a></p>

            <p>
              <a href="mailto:sales@loop.tv">sales@loop.tv</a>
              <a href="mailto:ir@loop.tv">ir@loop.tv</a>
              <a href="mailto:artists@loop.tv">artists@loop.tv</a>
            </p>
            <div className={`${styles.desktopOnly} ${styles.social}`}>
              <Link href="https://www.instagram.com/loopforbusiness/">
                <a target="_blank">
                  <Image src={instagramLogo} alt="Instagram" />
                </a>
              </Link>
              <Link href="https://www.facebook.com/loopforbusiness/">
                <a target="_blank">
                <Image src={facebookLogo} alt="Facebook" />
                </a>
              </Link>
              <Link href="https://www.linkedin.com/company/looptv/">
                <a target="_blank">
                  <Image src={linkedinLogo} alt="LinkedIn" />
                </a>
              </Link>
              <Link href="https://www.twitch.tv/loopmediainc">
                <a target="_blank">
                  <Image src={twitchLogo} alt="Twitch" />
                </a>
              </Link>
              <Link href="https://twitter.com/loopforbusiness">
                <a target="_blank">
                  <Image src={twitterLogo} alt="Twitter" />
                </a>
              </Link>              
            </div>
          </div>
          <div className={styles.wrap}>
            <div className={styles.label}>
              <div className={styles.logo}>
                <Image src={footerLogo} alt="Loop" />
              </div>
              <h3>Seattle</h3>
            </div>
            <p>
              150 nickerson st.
              <br />
              suite 305, seattle
              <br />
              wa 98109
            </p>

            <p>phone: <a href="tel:8007421439">800.742.1439</a></p>

            <p>
              <a href="mailto:support@loop.tv">support@loop.tv</a>
              <a href="mailto:clients@loop.tv">clients@loop.tv</a>
              <a href="mailto:content@loop.tv">content@loop.tv</a>
            </p>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.wrap}>
            <div className={styles.label}>
              <h3>Company</h3>
            </div>
            <p>
              <Link href="/privacy-policy">
                <a
                  className={
                    router.asPath === "/privacy-policy" ? styles.pageOn : styles.link
                  }
                >
                  privacy policy
                </a>
              </Link>
              <Link href="/terms-of-service">
                <a
                  className={
                    router.asPath === "/terms-of-service" ? styles.pageOn : styles.link
                  }
                >
                  terms of service
                </a>
              </Link>
              <Link href="/support">
                <a
                  className={
                    router.asPath === "/support" ? styles.pageOn : styles.link
                  }
                >
                  support
                </a>
              </Link>
              <Link href="/investors">
                <a>investors</a>
              </Link>
              <Link href="/leadership">
                <a>leadership</a>
              </Link>
              <Link href="/news">
                <a>news</a>
              </Link>
              <Link href="/learn">
                <a>learn</a>
              </Link>
              <Link href="/artists">
                <a>artists</a>
              </Link>
            </p>

            <p></p>

            <p>
              interested in<br />becoming a reseller?
              <br />
              get in touch at<br /><a href="mailto:reseller@loop.tv">reseller@loop.tv</a>
            </p>
          </div>
          <div className={styles.wrap}>
            <div className={styles.label}>
              <h3>Industries</h3>
            </div>
            {data &&
              <ul>
                {data.data.map((item) => (
                  <li key={item._id}>
                    <Link href={`/${item.slug.current}`}>
                      <a>{item.title}</a>
                    </Link>
                  </li>
                ))}
              </ul>
            }
          </div>
        </div>
      </div>
    </footer>
  );
}
