import styles from "./modal.module.scss";
import Image from "next/image";
import { useState, useRef, useEffect } from "react";
import Button from "../../components/button/button";
import loopPlayer from "../../public/images/Loop_Player_Modal.png";
import loopLogo from "../../public/images/looptv_logo.png";
import closeIcon from "../../public/images/hamburger_close.png";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId, geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import Router from "next/router";
import { fbEvent } from '@rivercode/facebook-conversion-api-nextjs';
import PhoneInput from 'react-phone-number-input/input'
import { formatPhoneNumber, isValidPhoneNumber  } from 'react-phone-number-input'
import { PortableText } from "@portabletext/react";
import myPortableTextComponents from "../utilities/portableTextStylePost";

const FreePlayer = ({ show, close }) => {

  const [data, setData] = useState(null);

  const closeModal = () => {
    close();
  };
  useEffect(() => {

    fetch('/api/getModalContent')
      .then((res) => res.json())
      .then((data) => {
        setData(data)
      })
  }, []);

  // form validation and submission

  const [formStatus, setFormStatus] = useState("show");
  const [formMsg, setFormMsg] = useState(null);
  const [addressValue, setAddressValue] = useState(null);
  const [formError, setFormError] = useState(false);
  const [urlErrorMessage, setUrlErrorMessage] = useState('');
  const [businessNameErrorMessage, setBusinessNameErrorMessage] = useState('');
  const [businessTypeErrorMessage, setBusinessTypeErrorMessage] = useState('');
  const [formFields, setFormFields] = useState({
    first_name: "",
    last_name: "",
    email: "",
    business_name: "",
    address_2: "",
    business_phone: "",
    business_website: "",
    business_type: "",
    number_venues: "1",
    number_tvs: "1",
    password: "",
  });

  function validateURL(url) {

    const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
    '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
    return !!urlPattern.test(url);
  }

  const setPhone = () => (e) => {
    setFormFields((prevState) => ({
      ...prevState,
      business_phone: e
    }));
  }

  const handleField = () => (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "business_type") {
      if (value === "") {
        setBusinessTypeErrorMessage("Please select a Business Type");
      } else {
        setBusinessTypeErrorMessage('');
      }
    }

    if (name === "business_website") {
      let urlCheck = validateURL(value);
      if (urlCheck) {
        setUrlErrorMessage('');
      } else {
        setUrlErrorMessage('Please include a valid URL');
      }
    }

    if (name === "business_name") {
      let nameValue = formFields['first_name'] + " " + formFields['last_name'];
      if (value === nameValue) {
        setBusinessNameErrorMessage("Business name can't match personal name");
      } else {
        setBusinessNameErrorMessage('');
      }
    }

    setFormFields((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  async function handleSubmit(e) {
    e.preventDefault();

    // final validation before submitting

    // valid phone number
    let phoneValid = isValidPhoneNumber(formFields.business_phone);
    let formatPhone = formatPhoneNumber(formFields.business_phone);
    if (!phoneValid) {
      alert("Please enter a valid Phone Number");
      return;
    }

    // valid business TYPE
    let typeValid = formFields['business_type'];
    if (typeValid === "") {
      alert("Please select a valid Business Type");
      return;
    }

    // valid business URL
    let urlValid = validateURL(formFields.business_website);
    if (!urlValid) {
      alert("Please enter a valid website URL");
      return;
    }

    // valid business name that doesn't equal fname+lname
    let nameValue = formFields['first_name'] + " " + formFields['last_name'];
    if (formFields['business_name'] === nameValue) {
      alert("Business name can't match personal name");
      return;
    }

    function getCookie (name) {
      let value = `; ${document.cookie}`;
      let parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }

    let campaign = getCookie('campaign');

    let geoCodeResponse = await geocodeByPlaceId(addressValue.value.place_id)
      .then(results => {
        const addressComponents = results[0].address_components;
        const addressNumber = addressComponents.filter(x => x?.types?.includes('street_number'))[0];
        const addressStreet = addressComponents.filter(x => x?.types?.includes('route'))[0];
        let addressCity = addressComponents.filter(x => x?.types?.includes('locality'))[0];
        if (!addressCity) {
          addressCity = addressComponents.filter(x => x?.types?.includes('sublocality'))[0];
        }
        const addressState = addressComponents.filter(x => x?.types?.includes('administrative_area_level_1'))[0];
        const addressZip = addressComponents.filter(x => x?.types?.includes('postal_code'))[0];
        const country = addressComponents.filter(x => x?.types?.includes('country'))[0];
        let address_1 = "";
        if (addressNumber) {
          address_1 = addressNumber.short_name + " " + addressStreet.short_name;
        } else {
          address_1 = addressStreet.short_name;
        }
        const state_code = country.short_name+"-"+addressState.short_name;
        let latLngResponse = geocodeByAddress(results[0].formatted_address)
        .then(results => {
          getLatLng(results[0])
          .then(latLong => {
            const data = {
              email: formFields.email,
              first_name: formFields.first_name,
              last_name: formFields.last_name,
              phone_number: formatPhone,
              screen_count: formFields.number_tvs,
              location_count: formFields.number_venues,
              business_name: formFields.business_name,
              address_1: address_1,
              address_2: formFields.address_2,
              city: addressCity.short_name,
              state_code: state_code,
              country_code: country.short_name,
              zip: addressZip.short_name,
              latitude: latLong.lat,
              longitude: latLong.lng,
              website: formFields.business_website,
              type: formFields.business_type,
              mobile_number: formatPhone,
              password: formFields.password,
              campaign: campaign
            }

            fetch("/api/free-player-submit", {
              method: "POST",
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data)
            }).then(results => results.json())
            .then(data => {

              if (data.message === "success") {

                // add custom event to FB conversion tracking
                fbEvent({
                  eventName: 'PageView', // ViewContent, AddToCart, InitiateCheckout or Purchase
                  products: [{
                    sku: 'player',
                    quantity: 1,
                  }]
                });

                closeModal();
                //Router.push("/thankyou?email="+formFields.email);
                window.location.href = "/thankyou?phone="+encodeURIComponent(formatPhone)+"&email="+encodeURIComponent(formFields.email);

              } else {
                setFormMsg(data.message);
              }
            });

          });
        });
      })
      .catch(error => console.error(error));

  }

  return (
    <>
      {show ? (
        <div className={styles.modalContainer}>
          <div className={styles.closeWrapper}>
            <div className={styles.image}>
              <Image
                src={loopLogo}
                layout="fill"
                objectFit="contain"
                alt="Background Image"
              />
            </div>
            <div className={styles.close} onClick={closeModal}>
              <div className={styles.backButton}>
                <Button label="take me back" />
              </div>
              <div className={styles.hamburgerButton}>
                <Image src={closeIcon} alt="Background Image" />
              </div>
            </div>
          </div>

          <div className={styles.modal}>
            <div className={styles.header}>
              {data &&
                <PortableText
                  value={data.data.headline}
                  components={myPortableTextComponents}
                />
              }
            </div>

            <div className={styles.subText}>
              {data &&
                <PortableText
                  value={data.data.bodytext}
                  components={myPortableTextComponents}
                />
              }
            </div>
            <div className={styles.formWrapper}>
              <div className={styles.imageWrapper}>
                <div className={styles.image}>
                  <Image src={loopPlayer} alt="Loop Player Device" />
                  {/*
                    <video autoPlay loop playsInline muted poster={loopLogo}>
                      <source src="/videos/3D-WALKTHROUGH-Alpha-512x512-0001.webm" />
                    </video>
                  */}
                </div>
              </div>
              <div className={styles.form}>
                {formStatus === "hide" ?
                  <div className={styles.formSent}>
                    <p>Thank you! We will be in touch shortly.</p>
                  </div>
                :
                  <form onSubmit={handleSubmit}>

                    <div className={styles.personalInfo}>

                      <input
                        type="text"
                        name="first_name"
                        placeholder="first name *"
                        required
                        onChange={handleField()} value={formFields.first_name}
                      />

                      <input
                        type="text"
                        name="last_name"
                        placeholder="last name *"
                        required
                        onChange={handleField()} value={formFields.last_name}
                      /> 

                      <input
                        type="text"
                        name="business_name"
                        placeholder="business name *"
                        required
                        onChange={handleField()} value={formFields.business_email}
                      />
                      <span className={styles.error}>{businessNameErrorMessage}</span>

                      <input
                        type="email"
                        name="email"
                        placeholder="business email *"
                        required
                        onChange={handleField()} value={formFields.email}
                      />

                      <select name="business_type" required onChange={handleField()} value={formFields.business_type}>
                        <option value="" disabled>
                            business type *
                        </option>
                        <optgroup label="Transit">
                        <option value="41527a00-6c74-11eb-b0ed-7b9b812be05c">Airports</option>
                        <option value="d91b30ac-6897-4d0c-9813-0165ab02b527">Buses Terminals</option>
                        <option value="70e9a178-b372-46e4-93d9-5ac07adc86dc">Subway</option>
                        <option value="58b78cca-057f-4dac-aa56-3bcf1b36dd8e">Train Stations</option>
                        </optgroup>
                        <optgroup label="Retail">
                        <option value="414c64f0-6c74-11eb-869a-7bde574ad8a1">Gas Stations</option>
                        <option value="414a23f0-6c74-11eb-9cba-afe8e801543d">Convenience Stores</option>
                        <option value="0ed2f398-56ed-43df-b863-3e081c16a303">Grocery</option>
                        <option value="34404804-ac2c-446b-8486-b7195e58fee9">Liquor Stores</option>
                        <option value="414eafe0-6c74-11eb-8f21-4bdcbfb37b03">Malls</option>
                        <option value="d40ab593-7b9b-4f9b-96f4-b94fb8c7eb2a">Dispensaries</option>
                        <option value="414ab590-6c74-11eb-b35e-23dd5ad02c7d">Pharmacies</option>
                        <option value="953f5a3f-e3f1-41dd-bbb7-d086cd5a5156">Parking Garages</option>
                        <option value="4150ec80-6c74-11eb-b724-6fd69511cfa2">Other Retail Stores</option>
                        </optgroup>
                        <optgroup label="Health and Beauty">
                        <option value="414cf8d0-6c74-11eb-93fb-2594282709d5">Gyms</option>
                        <option value="4148fdb0-6c74-11eb-81a5-7bce946f9dfa">Salons</option>
                        <option value="13149385-9325-4a72-b68e-e031461a3434">Spas</option>
                        </optgroup>
                        <optgroup label="Point of Care">
                        <option value="414d8d70-6c74-11eb-bbb1-a93803a5d95e">Doctor&rsquo;s Offices</option>
                        <option value="414fc440-6c74-11eb-a076-5913eecb8afb">Pets and Animal Care</option>
                        </optgroup>
                        <optgroup label="Education">
                        <option value="41516d00-6c74-11eb-a582-e5db56e818b9">Schools</option>
                        <option value="9f9ed045-9a42-4b75-b391-4596c6628e48">Colleges and Universities</option>
                        </optgroup>
                        <optgroup label="Offices">
                        <option value="415052a0-6c74-11eb-a096-59ee8adc24da">Office Buildings</option>
                        </optgroup>
                        <optgroup label="Entertainment">
                        <option value="413e2e90-6c74-11eb-a4a4-6f94b6e3c2ec">Bars</option>
                        <option value="414be680-6c74-11eb-844e-375a6d3612ee">Casual Dining</option>
                        <option value="940198d3-1592-4545-864a-fd5e5e85ad76">Quick Serve Restaurants</option>
                        <option value="414f4490-6c74-11eb-8042-a5dc870de5eb">Movie Theaters</option>
                        <option value="41499a90-6c74-11eb-8aaf-2bb8285c7cff">Casinos</option>
                        <option value="414e2390-6c74-11eb-9b97-dffbf20037f8">Hotels and Resorts</option>
                        <option value="4151e4d0-6c74-11eb-87e4-4578bc356687">Stadiums and Arenas</option>
                        <option value="bf5c7b7b-aff6-483f-bed6-ee105949f359">Sports Entertainment</option>
                        <option value="414b44d0-6c74-11eb-ad96-c3bbdeacf1a4">Family Entertainment</option>
                        </optgroup>
                        <optgroup label="Government">
                        <option value="1bd57a1f-26b2-408f-be47-ebe488cc5c49">DMVs</option>
                        <option value="71296ff4-a54f-47ef-bfa2-16cfe06cf183">Military Bases</option>
                        </optgroup>
                        <optgroup label="Financial">
                        <option value="ca83665c-9c42-47b9-acd3-edfc2f4d372f">Banks</option>
                        </optgroup>
                        <optgroup label="Residential">
                        <option value="7582c09c-136d-406b-a05c-6f533cccb0a6">Apartment Buildings</option>
                        </optgroup>
                      </select>
                      <span className={styles.error}>{businessTypeErrorMessage}</span>

                      <input
                        className={styles.full}
                        type="text"
                        name="business_website"
                        placeholder="business website *"
                        required
                        onChange={handleField()} value={formFields.business_website}
                      />
                      <span className={styles.error}>{urlErrorMessage}</span>

                      <div className={styles.address}>
                        <GooglePlacesAutocomplete
                          apiKey="AIzaSyD04bM-w8FHi_c0igUR_6V0gCht-wBXj_w"
                          minLengthAutocomplete={5}
                          selectProps={{
                            placeholder: "business address *",
                            className: "rounded_border",
                            addressValue,
                            onChange: setAddressValue,
                          }}
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: ['us'],
                            }
                          }}
                        />
                          <input
                            type="text"
                            name="address_2"
                            placeholder="floor, suite, etc (optional)"
                            onChange={handleField()} value={formFields.address_2}
                          />

                          <PhoneInput
                            name="business_phone"
                            country="US"
                            placeholder="business phone *"
                            required
                            value={formFields.business_phone}
                            onChange={setPhone()}
                          />

                          {/*
                          <input
                            type="text"
                            name="business_phone"
                            placeholder="*business phone"
                            required
                            onChange={handleField()} value={formFields.business_phone}
                          />
                         */}
                      </div>



                      {/*



                      <div className={styles.sideBySideNum}>
                        <div className={styles.item}>
                          <label htmlFor="number_venues">Number of venues</label>
                          <div className={styles.dropdown}>
                            <select defaultValue="1" name="number_venues" id="number_venues" onChange={handleField()} value={formFields.number_venues}>
                              <option value="1">
                                1
                              </option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="10+">10+</option>
                            </select>
                          </div>
                        </div>
                        <div className={styles.item}>
                          <label htmlFor="number_tvs">How many installed TVs?</label>
                          <div className={styles.dropdown}>
                            <select defaultValue="1" name="number_tvs" id="number_tvs" onChange={handleField()} value={formFields.number_tvs}>
                              <option value="1">
                                1
                              </option>
                              <option value="1">1</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="10+">10+</option>
                            </select>
                          </div>
                        </div>
                      </div>

                        */ }



                      <input
                        className={styles.full}
                        type="password"
                        name="password"
                        placeholder="create a password *"
                        required
                        onChange={handleField()} value={formFields.password}
                      />
                      {/*
                      <p className={styles.disclaimer}>* Due to licensing requirements, Loop is unable to ship to a residential address.</p>
                      */}
                      {!formError &&
                        <div className={styles.button}>
                          <Button label="Send me a free player" width="100%" />
                        </div>
                      }
                      {formMsg != null &&
                        <div className={styles.formMsg}>
                          <p>{formMsg}</p>
                        </div>
                      }
                    </div>
                  </form>
                }
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default FreePlayer;
