import "../styles/globals.scss";
import Layout from "../components/layout";
import Modal from "../components/modals/freePlayer.js";
import { useState, useEffect } from "react";
import Cookies from 'js-cookie'
import MainModal from "../components/modals/mainModal.js";
import TagManager from 'react-gtm-module';
import { FBPixelScript, FBPixelProvider } from '@rivercode/facebook-conversion-api-nextjs/components';

function MyApp({ Component, pageProps }) {

  const [modal, setModal] = useState(false);
  const ModalToggle = () => {
    if (modal) {
        console.log("close");
        document.querySelector("body").classList.remove("overlay_on");
    } else {
        console.log("open");
        document.querySelector("body").classList.add("overlay_on");
    }
    setModal(!modal);
  }
  const [mainModal, setMainModal] = useState(false);
  const MainModalToggle = () => setMainModal(!mainModal);

  useEffect(() => {

    TagManager.initialize({ gtmId: 'GTM-WFNR7WH' });

    window.pypestreamConfig = {
      APP_ID: "2ff4e74f-7a95-4d12-83a5-fb96ee134969",
      screen_name: 'landing'
    };
    
    var pypeScript = document.createElement("script");
    pypeScript.type = "text/javascript";
    pypeScript.src = "https://web.pypestream.com/launcher.js?ts=" + Date.now();
    pypeScript.async = true;
    document.getElementsByTagName("head")[0].appendChild(pypeScript);

    let hasSeenGate = Cookies.get("sawGate");
    if (hasSeenGate !== "yes") {
        // show modal - TURNED OFF FOR A/B TESTING ON 5/12/22
        setMainModal(false);
        // set cookie
        setGateCookie(30);
    }

    // set cookies for player registration and marketing campaigns
    var getCookie = function (name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == " ") c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      
      }
      return null;
  }
  var setCookie = function (cname, cvalue, exdays) {
      //Cookies.set(cname, cvalue, { expires: exdays });
      var d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";" + "domain=.loop.tv;path=/";
      //document.cookie = cname + "=" + cvalue + ";" + expires + ";" + "domain=localhost;path=/";

  }
  var getUrlParameter = function(name) {
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      var results = regex.exec(location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };
  var utmTerm = getUrlParameter("utm_term");
  var utmContent = getUrlParameter("utm_content");
  var utmSource = getUrlParameter("utm_source");

  if (utmTerm) {
      if (utmContent) {
          utmTerm += (" / " + utmContent);
      }
      if (utmSource) {
          utmTerm += (" / " + utmSource);
      }
      setCookie("campaign", "utm -> " + utmTerm, 365);
  }
  else if (document.referrer && document.referrer.indexOf("http") === 0) {
      var campaign = getCookie("campaign");
      if (campaign && campaign.indexOf("organic") !== 0) {
          var ref = new URL(document.referrer);
          var host = ref.hostname.replace("www.", "");
          if (host !== "loop.tv") {
              setCookie("campaign", "organic -> " + host, 365);
          }
      }
  }
  //var promoCode = window.location.pathname.split("/").filter(function(e){return e}).pop();
  var promoCode = getUrlParameter("campaign");
  if (promoCode && promoCode.length > 0 && promoCode !== "business") {
      setCookie("campaign", promoCode, 365);
  }
  else {
      var affiliateRef = getUrlParameter("click_id");
      if (affiliateRef) {
          setCookie("campaign", "affiliate_" + affiliateRef, 365);
      }
  }

  }, [mainModal])

  function setGateCookie(expDays) {
    Cookies.set('sawGate', 'yes', { expires: expDays });
  }  

  return (
    <>
    <FBPixelScript />
    <FBPixelProvider>
    <Layout modalClick={ModalToggle}>
    <Component {...pageProps} modalClick={ModalToggle} />
    <Modal show={modal} close={ModalToggle} />
    <MainModal show={mainModal} close={MainModalToggle} />
    </Layout>
    </FBPixelProvider>
    </>
  );
}

export default MyApp;
